import React from 'react';
import { HotKeys } from 'react-hotkeys';
import useUserStore from 'stores/userStore';
import { useRouter } from 'next/router';

/**
 * @returns Hotkeys for the entire app
 *
 * Docs:
 * - https://www.npmjs.com/package/react-hotkeys
 *
 */
export default function Hotkeys({ children }) {
  const user = useUserStore((state) => state.user);
  const router = useRouter();

  const keyMap = {
    OPEN_ADMIN: 'ctrl+r `',
    OPEN_UDATES: 'ctrl+r u',
    OPEN_MESSAGES: 'ctrl+r m',
    OPEN_PROFILE: 'ctrl+r p',
    OPEN_SETTINGS: 'ctrl+r s',
    OPEN_HELP: 'ctrl+r h',
    OPEN_CALLS: 'ctrl+r c',
    OPEN_LEADS: 'ctrl+r l',
    OPEN_JABRONI_ZONE: 'j+z',
  };

  const handlers = {
    OPEN_ADMIN: (event) => {
      if (!user.is_staff) return;

      event.preventDefault();

      window.open(
        `${process.env.NEXT_PUBLIC_API_BASE_URL_EXTERNAL}/admin/core/user/${user.id}/change/`,
        '_blank',
      );
    },
    OPEN_HELP: (event) => {
      window.open(
        'https://rejigg.notion.site/Rejigg-Help-Center-31c5a931a9b4410baf8f5d3f2b5a54c4',
        '_blank',
      );
    },

    OPEN_UDATES: (event) => {
      router.push('/updates');
    },
    OPEN_MESSAGES: (event) => {
      router.push('/conversations');
    },
    OPEN_PROFILE: (event) => {
      router.push('/profile');
    },
    OPEN_SETTINGS: (event) => {
      router.push('/settings');
    },
    OPEN_CALLS: (event) => {
      router.push('/meetings');
    },
    OPEN_LEADS: (event) => {
      router.push('/leads');
    },
    OPEN_JABRONI_ZONE: (event) => {
      router.push('/jabroni-zone');
    },
  };

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      {children}
    </HotKeys>
  );
}
